import { useEffect, useState } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import Notice from '../modules/notice';
import ResearchGate from '../modules/researchgate';
import RelatedSites from '../modules/relatedsites';
import Visit from '../modules/visit';
import Google from '../modules/google';
import { URL_ABOUT } from './Url';
import './About.css';

function About({ content }) {
    const [about, setAbout] = useState(null);

    useEffect(() => {
        // CSRF 토큰을 메타 태그에서 가져오기
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        // Axios 요청에 CSRF 토큰을 헤더로 추가
        axios.get(URL_ABOUT, {
            headers: {
                'X-CSRF-Token': csrfToken
            }
        })
            .then(response => {
                setAbout(response.data);
            })
            .catch(error => {
                // about이 없는 경우에도 오류 발생 가능
                console.error(error);
                setAbout(null);
            });
    }, []);

    return (
        <div className="container">
            <div className="row">
                {/* Page content */}
                <div className="col-lg-8">
                    {/* Featured blog post */}
                    <div className="card mb-4">
                        {about ? (
                            <div className="about-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about.content) }} />
                        ) : (
                            'No about information available.'
                        )}
                    </div>
                </div>
                {/* Side widgets */}
                <div className="col-lg-4">
                    {/* Notice widget */}
                    <div className="card mb-3" id="widget-notice"><Notice /></div>
                    {/* ResearchGate widget */}
                    <div className="card mb-3" id="widget-researchgate"><ResearchGate /></div>
                    {/* Related sites widget */}
                    <div className="card mb-3" id="widget-relatedsites"><RelatedSites /></div>
                    {/* Counter widget */}
                    <div className="card mb-3" id="widget-counter"><Visit /></div>
                    {/* Google search widget */}
                    <div className="card mb-3" id="widget-google"><Google content={content} /></div>
                </div>
            </div>
        </div>
    );
}

export default About;
