import DOMPurify from 'dompurify';

const headerStyle = {
    backgroundImage: "url('./img/background.png')",
    backgroundSize: "cover",
}

function Header({ content }) {
    var mainContents = "";
    if (content.length === 0) {
        mainContents = (
            <div></div>
        );
    }
    else {
        mainContents = (<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.main_content) }}></div>);
    }

    return (
        <header class="py-5 bg-light border-bottom mb-4" style={headerStyle}>
            <div class="container">
                <div class="p-5 text-left" id="main-content">{mainContents}</div>
            </div>
        </header>
    );
}

export default Header;