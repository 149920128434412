import { useEffect, useState } from "react";
import axios from "axios";
import { URL_VISIT } from "../components/Url";

function Visit() {
    const [visit, setVisit] = useState({ today: null, total: null });

    useEffect(() => {
        // CSRF 토큰을 메타 태그에서 가져오기
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        axios.get(URL_VISIT, {
            headers: {
                'X-CSRF-Token': csrfToken,
            },
        })
            .then((response) => {
                setVisit(response.data);
            })
            .catch((error) => {
                console.error('Error fetching visit data:', error);
                setVisit({ today: null, total: null });
            });
    }, []);

    return (
        <div>
            <div className="card-header">Visitors</div>
            <div className="card-body">
                {visit.today !== null && visit.total !== null ? (
                    <>
                        Today: {visit.today}<br />Total: {visit.total}
                    </>
                ) : (
                    <div>No information available</div>
                )}
            </div>
        </div>
    );
}

export default Visit;
