import { useEffect, useState } from "react";
import axios from "axios";
import { URL_RESEARCHGATE } from "../components/Url";

const cardHeaderSubscript = {
    fontSize: "x-small"
};

function ResearchGate() {
    const [scores, setScores] = useState({});

    useEffect(() => {
        // CSRF 토큰을 메타 태그에서 가져오기
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        axios.get(URL_RESEARCHGATE, {
            headers: {
                'X-CSRF-Token': csrfToken,
            },
        }).then((response) => {
            setScores(response.data);
        });
    }, []);

    return (
        <div>
            <div className="card-header">
                ResearchGate{" "}
                <span
                    className="text-secondary"
                    id="widget-researchgate-created-at"
                    style={cardHeaderSubscript}
                >
                    Updated on {scores.created_at ? scores.created_at.substring(0, 10) : "N/A"}
                </span>
            </div>
            <div className="card-body">
                {Object.keys(scores).length === 0 ? (
                    <div>No score available</div>
                ) : (
                    <>
                        Publications{" "}
                        <span
                            className="badge bg-secondary fw-bold"
                            id="widget-researchgate-publications"
                        >
                            {scores.publications_score}
                        </span>
                        <br />
                        Reads{" "}
                        <span
                            className="badge bg-secondary fw-bold"
                            id="widget-researchgate-reads"
                        >
                            {scores.reads_score}
                        </span>
                        <br />
                        Citations{" "}
                        <span
                            className="badge bg-secondary fw-bold"
                            id="widget-researchgate-citations"
                        >
                            {scores.citations_score}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
}

export default ResearchGate;
