import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { format } from 'date-fns';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import bootstrap JS

function Notice() {
    const [notices, setNotices] = useState([]);
    const [modalContent, setModalContent] = useState({ title: '', content: '', created_at: '', user_name: '', user_email: '' });

    useEffect(() => {
        // CSRF 토큰을 메타 태그에서 가져오기
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        axios.get('/api/board/widget/notice', {
            headers: {
                'X-CSRF-Token': csrfToken,
            },
        })
            .then(response => {
                if (response.data.status === 'success') {
                    setNotices(response.data.posts);
                }
            })
            .catch(error => console.error('Error fetching notices:', error));
    }, []);

    const handleTitleClick = (id) => {
        // CSRF 토큰을 메타 태그에서 가져오기
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        axios.get(`/api/board/notice/${id}`, {
            headers: {
                'X-CSRF-Token': csrfToken,
            },
        })
            .then(response => {
                if (response.data.status === 'success') {
                    const post = response.data.post;
                    post.created_at = format(new Date(post.created_at), 'MM/dd/yyyy hh:mm:ss');
                    setModalContent(post);
                    const noticeModal = new window.bootstrap.Modal(document.getElementById('noticeModal'));
                    noticeModal.show();
                }
            })
            .catch(error => console.error('Error fetching notice:', error));
    };

    return (
        <div>
            <div className="card-header">Notice</div>
            <div className="card-body">
                {notices.length > 0 ? (
                    <ul className="list-group list-group-flush">
                        {notices.map((notice) => (
                            <li key={notice.id} className="list-group-item">
                                <button type="button" className="btn btn-link p-0" onClick={() => handleTitleClick(notice.id)}>
                                    {notice.title}
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div>No post available</div>
                )}
            </div>

            <div className="modal fade" id="noticeModal" tabIndex="-1" aria-labelledby="noticeModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="noticeModalLabel">Notice</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5>{modalContent.title}</h5>
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modalContent.content) }} />
                            <p className="text-muted text-end">{modalContent.created_at}<br />{modalContent.user_name} ({modalContent.user_email})</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notice;
