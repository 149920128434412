import { useEffect, useState } from "react";
import axios from "axios";
import { URL_RELATEDSITES } from "../components/Url";

function RelatedSites() {
    const [sites, setSites] = useState([]);

    useEffect(() => {
        // CSRF 토큰을 메타 태그에서 가져오기
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        axios.get(URL_RELATEDSITES, {
            headers: {
                'X-CSRF-Token': csrfToken,
            },
        })
            .then((response) => {
                setSites(response.data);
            });
    }, []);

    const handleSiteClick = async (siteId) => {
        // CSRF 토큰을 메타 태그에서 가져오기
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        await axios.post(`${URL_RELATEDSITES}/${siteId}/increment`, {}, {
            headers: {
                'X-CSRF-Token': csrfToken,
            },
        });
    };

    return (
        <div>
            <div className="card-header">Related sites</div>
            <div className="card-body">
                {sites.length === 0 ? (
                    <div className="row">
                        <div className="col-sm-6">No site available</div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-sm-6">
                            <ul className="list-unstyled">
                                {sites.map((site, index) => (
                                    <li key={index}>
                                        <a
                                            href={site.url}
                                            className="link-primary link-underline link-underline-opacity-0"
                                            onClick={() => handleSiteClick(site.id)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {site.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default RelatedSites;
