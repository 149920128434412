
function Footer({ content }) {
    return (
        <footer style={{ backgroundColor: content.color_footer }} className="py-2">
            <div className="container">
                <p className="m-0 text-center text-white">Copyright &copy; {content.domain_name} 2024</p>
            </div>
        </footer>
    );
}

export default Footer;
