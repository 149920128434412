import React, { useState } from 'react';

function Google({ content }) {
    const [searchTerm, setSearchTerm] = useState("");

    if (!content) {
        return <div>Loading...</div>; // 데이터를 불러오는 동안 표시할 내용
    }

    const handleSubmit = (event) => {
        event.preventDefault(); // 기본 폼 제출 동작을 막음
        const actionUrl = `https://www.google.com/search?q=site%3A${content.domain_name}+${encodeURIComponent(searchTerm)}`;
        window.open(actionUrl, '_blank'); // 새로운 탭에서 구글 검색 결과를 엽니다.
    };

    return (
        <div>
            <div className="card-header">Search {content.domain_name} by Google</div>
            <div className="card-body">
                <form onSubmit={handleSubmit} id="form-search">
                    <div className="input-group">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter search term..."
                            aria-label="Enter search term..."
                            aria-describedby="button-search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // 입력값을 상태로 관리
                        />
                        <input className="btn btn-primary" id="button-search" type="submit" value="Go!" />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Google;
