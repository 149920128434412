// errorHandler.js

function handleErrorResponse(response) {
    if (response.status !== 'success') {
        displayErrorModal(response.error || 'An unknown error occurred.');
        return false;
    }
    return true;
}

function displayErrorModal(errorMessage) {
    const modalContent = `
        <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="errorModalLabel">Error</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        ${errorMessage}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    `;

    document.body.insertAdjacentHTML('beforeend', modalContent);
    const errorModal = new window.bootstrap.Modal(document.getElementById('errorModal'));
    errorModal.show();
}

export { handleErrorResponse, displayErrorModal };
