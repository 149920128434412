import { useEffect } from 'react';
import './NotFound.css';

function NotFound({ content }) {
    useEffect(() => {
        // 루트 요소를 비웁니다.
        const rootElement = document.getElementById('root');
        if (rootElement) {
            rootElement.innerHTML = '';
        }

        // 404.html 파일의 내용을 로드하고 삽입합니다.
        fetch('/404.html')
            .then(response => response.text())
            .then(data => {
                if (rootElement) {
                    rootElement.innerHTML = data;
                }
            })
            .catch(error => console.error('Error fetching 404.html:', error));
    }, []);

    // 이 컴포넌트 자체는 아무 것도 렌더링하지 않습니다.
    return null;
}

export default NotFound;