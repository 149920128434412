const URL_CONFIGURATION = "/api/configuration";
const URL_ABOUT = "/api/about";
const URL_RELATEDSITES = "/api/relatedsites";
const URL_RESEARCHGATE = "/api/researchgate/latest";
const URL_PAPERS = "/api/papers";
const URL_VISIT = "/api/visit";
const URL_LOGIN = "/auth/google/login";
const URL_LOGOUT = "/auth/google/logout";
const URL_BOARD = "/api/board";

export { URL_CONFIGURATION, URL_ABOUT, URL_RELATEDSITES, URL_RESEARCHGATE, URL_PAPERS, URL_VISIT, URL_LOGIN, URL_LOGOUT, URL_BOARD };