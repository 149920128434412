import { useEffect, useState, useRef } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Home from './components/Home';
import About from './components/About';
import Health from './components/Health';
import Papers from './components/Papers';
import Board from './components/Board';
import PostDetail from './components/PostDetail';
import NotFound from './components/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';
import { URL_CONFIGURATION, URL_LOGIN, URL_LOGOUT } from './components/Url';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function App() {
    const [content, setContent] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [relatedSites, setRelatedSites] = useState([]);

    const navbarToggleRef = useRef(null); // Add a ref for the navbar toggle button

    // CSRF 토큰 가져오기
    const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

    useEffect(() => {
        axios.get('/auth/check', {
            withCredentials: true,
            headers: {
                'X-CSRF-Token': csrfToken
            }
        })
            .then((response) => {
                const { data } = response;
                if (data.user) {
                    setIsLoggedIn(true);
                    setUserInfo(data.user);
                    setIsAdmin(data.user.is_admin);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [csrfToken]);

    useEffect(() => {
        axios.get(URL_CONFIGURATION, {
            headers: {
                'X-CSRF-Token': csrfToken
            }
        })
            .then(response => {
                setContent(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [csrfToken]);

    useEffect(() => {
        axios.get('/api/relatedsites', {
            headers: {
                'X-CSRF-Token': csrfToken
            }
        })
            .then(response => {
                setRelatedSites(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [csrfToken]);

    const handleNavLinkClick = () => {
        const navbarToggleButton = navbarToggleRef.current;
        if (navbarToggleButton && window.getComputedStyle(navbarToggleButton).display !== 'none') {
            navbarToggleButton.click(); // Simulate a click on the navbar toggle button to collapse the menu
        }
    };

    return (
        <div style={{ backgroundColor: content.color_background }}>
            <div id="div-navbar">
                <Navbar expand="lg" className="navbar-dark" style={{ backgroundColor: content.color_navbar }}>
                    <Container>
                        <Navbar.Brand href="/">{content.site_name}</Navbar.Brand>
                        <Navbar.Toggle ref={navbarToggleRef} aria-controls="navbarSupportedContent" />
                        <Navbar.Collapse id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item"><Link className="nav-link" to="/" onClick={handleNavLinkClick}>Home</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/about" onClick={handleNavLinkClick}>About</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/health" onClick={handleNavLinkClick}>Health</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/papers" onClick={handleNavLinkClick}>Papers</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/board" onClick={handleNavLinkClick}>Board</Link></li>
                                {
                                    loading ? (
                                        <li className="nav-item"><span className='nav-link'>Loading...</span></li>
                                    ) : (
                                        isLoggedIn && isAdmin && (
                                            <li className="nav-item"><a className="nav-link" href="/admin" onClick={handleNavLinkClick}>Admin</a></li>
                                        )
                                    )
                                }
                                {
                                    loading ? (
                                        <li className="nav-item"><span className='nav-link'>Loading...</span></li>
                                    ) : (
                                        isLoggedIn ? (
                                            <>
                                                <li className="nav-item"><a className="nav-link" href={URL_LOGOUT} onClick={handleNavLinkClick}>Logout</a></li>
                                                <li className="nav-item" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={userInfo.picture} alt={userInfo.name} width="16" height="16" />&nbsp;
                                                    <span className="text-secondary">{userInfo.name}</span>
                                                </li>
                                            </>
                                        ) : (
                                            <li className="nav-item"><a className="nav-link" href={URL_LOGIN} onClick={handleNavLinkClick}>Login</a></li>
                                        )
                                    )
                                }
                                {
                                    content.link_youtube && (
                                        <li className="nav-item">
                                            <a className="nav-link" href={content.link_youtube} aria-label="CMC Youtube Channel">
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-youtube"
                                                    viewBox="0 0 16 16">
                                                    <path
                                                        d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                                                </svg>
                                            </a>
                                        </li>
                                    )
                                }
                                {
                                    content.link_hospital && (
                                        <li className="nav-item">
                                            <a className="nav-link" href={content.link_hospital} aria-label="CMC Homepage">
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hospital"
                                                    viewBox="0 0 16 16">
                                                    <path
                                                        d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5Zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9h-.5Zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Z" />
                                                    <path
                                                        d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1V1Zm2 14h2v-3H7v3Zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm0-14H6v1h4V1Zm2 7v7h3V8h-3Zm-8 7V8H1v7h3Z" />
                                                </svg>
                                            </a>
                                        </li>
                                    )
                                }
                                <NavDropdown title="Shortcuts" id="basic-nav-dropdown">
                                    <NavDropdown.Header>Search for papers</NavDropdown.Header>
                                    <NavDropdown.Item href="https://pubmed.ncbi.nlm.nih.gov/?term=su+nam+lee+catholic&sort=date">PubMed</NavDropdown.Item>
                                    <NavDropdown.Item href="https://www.researchgate.net/profile/Su-Nam-Lee-2">ResearchGate</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Header>Related sites</NavDropdown.Header>
                                    {
                                        relatedSites.map(site => (
                                            <NavDropdown.Item key={site.id} href={site.url} onClick={handleNavLinkClick}>{site.name}</NavDropdown.Item>
                                        ))
                                    }
                                </NavDropdown>
                            </ul>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <div id="div-header"><Header content={content} /></div>
            <Routes>
                <Route path="/" element={<Home content={content} />} />
                <Route path="/about" element={<About content={content} />} />
                <Route path="/health" element={<Health content={content} userInfo={userInfo} isLoggedIn={isLoggedIn} />} />
                <Route path="/papers" element={<Papers content={content} />} />
                <Route path="/board" element={<Board content={content} userInfo={userInfo} isLoggedIn={isLoggedIn} />} />
                <Route path="/board/:id" element={<PostDetail content={content} userInfo={userInfo} isLoggedIn={isLoggedIn} boardName="board" />} />
                <Route path="/health/:id" element={<PostDetail content={content} userInfo={userInfo} isLoggedIn={isLoggedIn} boardName="health" />} />
                <Route path="*" element={<NotFound content={content} />} />
            </Routes>
            <div id="div-footer"><Footer content={content} /></div>
        </div>
    );
}

export default App;
