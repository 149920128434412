import React, { useEffect, useState, useRef } from 'react';
import { URL_PAPERS } from './Url';
import Notice from '../modules/notice';
import ResearchGate from '../modules/researchgate';
import RelatedSites from '../modules/relatedsites';
import Visit from '../modules/visit';
import Google from '../modules/google';
import { select, forceSimulation, forceLink, forceManyBody, forceCenter, forceCollide, zoom } from 'd3';
import 'bootstrap/dist/css/bootstrap.min.css';

const MAX_NODES = 100;  // Maximum number of nodes to display fully scaled
const MIN_NODE_SIZE = 2; // Minimum node radius
const MAX_NODE_SIZE = 20; // Maximum node radius
const MIN_HEIGHT = 200; // Minimum height of the chart
const MAX_HEIGHT = 400; // Maximum height of the chart

const ResearcherGraph = () => {
    const [data, setData] = useState(null);
    const svgRef = useRef();
    const wrapperRef = useRef();

    useEffect(() => {
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        fetch('/api/researchers/graph', {
            headers: {
                'X-CSRF-Token': csrfToken,
            }
        })
            .then(response => response.json())
            .then(data => {
                const filteredNodes = data.nodes.filter(node => node.paper_count > 0);
                const filteredLinks = data.links.filter(link => {
                    const sourceNode = filteredNodes.find(node => node.id === link.source);
                    const targetNode = filteredNodes.find(node => node.id === link.target);
                    return (sourceNode.is_self && !targetNode.is_self) || (!sourceNode.is_self && targetNode.is_self);
                });

                setData({ nodes: filteredNodes, links: filteredLinks });
            })
            .catch(error => console.error('Error fetching graph data:', error));
    }, []);

    useEffect(() => {
        if (data) {
            const svg = select(svgRef.current);
            const width = wrapperRef.current.clientWidth;
            const nodeCount = data.nodes.length;
            const scaleFactor = Math.min(1, MAX_NODES / nodeCount); // Scale down if more nodes than MAX_NODES
            const calculatedHeight = data.nodes.length * 15;
            const height = Math.min(MAX_HEIGHT, Math.max(MIN_HEIGHT, calculatedHeight));

            // Reset SVG content
            svg.selectAll("*").remove();

            // Add a group element to apply zoom transformations
            const g = svg.append('g');

            // Define zoom behavior
            const zoomBehavior = zoom()
                .scaleExtent([0.5, 5])  // Set the scale limits for zooming
                .on('zoom', (event) => {
                    g.attr('transform', event.transform);
                    constrainTransform(event.transform, width, height, svg);
                });

            // Apply zoom behavior to the svg element
            svg.call(zoomBehavior);

            const simulation = forceSimulation(data.nodes)
                .force('link', forceLink(data.links)
                    .id(d => d.id)
                    .distance(d => 300 / Math.sqrt(d.value))) // 논문 수에 따라 거리 설정
                .force('charge', forceManyBody().strength(-200 * scaleFactor))
                .force('center', forceCenter(width / 2, height / 2))
                .force('collide', forceCollide().radius(d => Math.sqrt(d.paper_count) * scaleFactor * 5 + 15).strength(1))
                .alphaDecay(0.02)
                .velocityDecay(0.6)
                .on('tick', ticked);

            const link = g.append('g')
                .attr('stroke', '#999')
                .attr('stroke-opacity', 0.6)
                .selectAll('line')
                .data(data.links)
                .enter().append('line')
                .attr('stroke-width', d => Math.sqrt(d.value) * scaleFactor);

            const node = g.append('g')
                .attr('stroke', '#fff')
                .attr('stroke-width', 1.5)
                .selectAll('circle')
                .data(data.nodes)
                .enter().append('circle')
                .attr('r', d => Math.max(MIN_NODE_SIZE, Math.min(MAX_NODE_SIZE, Math.sqrt(d.paper_count) * scaleFactor * 5)))
                .attr('fill', d => d.is_self ? 'orange' : 'steelblue')
                .on('click', (event, d) => {
                    if (d.link) {
                        window.open(d.link, '_blank');
                    }
                })
                .on('mouseover', function (event, d) {
                    select(this).attr('fill', 'red');
                })
                .on('mouseout', function (event, d) {
                    select(this).attr('fill', d.is_self ? 'orange' : 'steelblue');
                })
                .call(drag(simulation));

            const labels = g.append('g')
                .selectAll('text')
                .data(data.nodes)
                .enter().append('text')
                .attr('x', 6)
                .attr('y', 3)
                .attr('font-size', d => `${Math.max(8, Math.sqrt(d.paper_count) * scaleFactor * 2)}px`)
                .text(d => d.name)
                .on('click', (event, d) => {
                    if (d.link) {
                        window.open(d.link, '_blank');
                    }
                })
                .on('mouseover', function () {
                    select(this).style('cursor', 'pointer');
                });

            function ticked() {
                link
                    .attr('x1', d => d.source.x)
                    .attr('y1', d => d.source.y)
                    .attr('x2', d => d.target.x)
                    .attr('y2', d => d.target.y);

                node
                    .attr('cx', d => d.x)
                    .attr('cy', d => d.y);

                labels
                    .attr('x', d => d.x + 10)
                    .attr('y', d => d.y);
            }

            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const { width } = entry.contentRect;
                    svg.attr('width', width).attr('height', height);
                    simulation.force('center', forceCenter(width / 2, height / 2)).alpha(0.5).restart();
                }
            });

            resizeObserver.observe(wrapperRef.current);

            return () => {
                resizeObserver.disconnect();
                simulation.stop();
            };
        }
    }, [data]);

    function drag(simulation) {
        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        return function (selection) {
            selection
                .on('start', dragstarted)
                .on('drag', dragged)
                .on('end', dragended);
        };
    }

    function constrainTransform(transform, width, height, svg) {
        const scale = transform.k;
        const xOffset = Math.max(Math.min(0, transform.x), width - width * scale);
        const yOffset = Math.max(Math.min(0, transform.y), height - height * scale);

        svg.attr('transform', `translate(${xOffset}, ${yOffset}) scale(${scale})`);
    }

    return (
        <div ref={wrapperRef} className="border rounded p-3" style={{ width: '100%', height: 'auto', margin: '0 auto', overflow: 'hidden' }}>
            <svg ref={svgRef} style={{ width: '100%', height: 'auto', minHeight: `${MIN_HEIGHT}px`, maxHeight: `${MAX_HEIGHT}px`, overflow: 'visible' }}></svg>
        </div>
    );
};

const PapersList = ({ content }) => {
    const [papers, setPapers] = useState([]);
    const [pageNumber, setPageNumber] = useState(1); // 페이지 번호는 1부터 시작
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const pageSize = 5;

    useEffect(() => {
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        fetch(`${URL_PAPERS}?page=${pageNumber}&limit=${pageSize}`, {
            headers: {
                'X-CSRF-Token': csrfToken,
            }
        })
            .then(response => response.json())
            .then(data => {
                setPapers(data.papers);
                setTotalPages(data.totalPages);
                setTotalRecords(data.totalRecords);
            })
            .catch(error => console.error('Error fetching papers:', error));
    }, [pageNumber]);

    const startPage = Math.floor((pageNumber - 1) / pageSize) * pageSize + 1;
    const tempEndPage = startPage + pageSize - 1;
    const endPage = tempEndPage < totalPages ? tempEndPage : totalPages;

    return (
        <div className="container">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css"
                integrity="sha512-SnH5WK+bZxgPHs44uWIX+LLJAJ9/2PkPKZ5QiAj6Ta86w+fsb2TkcmfRyVX3pBnMFcV7oQPJkl9QevSCWr3W6A=="
                crossOrigin="anonymous" referrerPolicy="no-referrer" />
            <div className="row">
                <div className="col-lg-8">
                    {/* 연구자 그래프 */}
                    <ResearcherGraph /><br />
                    <div className="list-group">
                        {papers.map((item, index) => (
                            <div key={index} className="list-group-item list-group-item-action" onClick={() => window.open(item.link, '_blank')} style={{ cursor: 'pointer' }}>
                                <h6 className="mb-1"><strong>{totalRecords - ((pageNumber - 1) * pageSize + index)}. {item.title}</strong></h6>
                                <p className="mb-1">{item.year}.{String(item.month).padStart(2, "0")} ∙ {item.journal}</p>
                                <p className="mb-1">
                                    <i className="fa-regular fa-user"></i>&nbsp;&nbsp;
                                    {item.researchers.map((researcher, idx) => (
                                        <span key={idx}>
                                            {researcher.is_self ? <strong>{researcher.name}</strong> : researcher.name}
                                            {idx < item.researchers.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                </p>
                            </div>
                        ))}
                        <br />
                        {papers.length > 0 && (
                            <div>
                                <ul className="pagination justify-content-center">
                                    <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1}>
                                            <span aria-hidden="true">&lt;</span>
                                        </button>
                                    </li>
                                    {[...Array(endPage - startPage + 1)].map((_, i) => {
                                        const page = startPage + i;
                                        return (
                                            <li key={page} className={`page-item ${page === pageNumber ? 'active' : ''}`}>
                                                <button className="page-link" onClick={() => setPageNumber(page)}>{page}</button>
                                            </li>
                                        );
                                    })}
                                    <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === totalPages}>
                                            <span aria-hidden="true">&gt;</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                {/*Side widgets*/}
                <div className="col-lg-4">
                    {/*Notice widget*/}
                    <div className="card mb-3" id="widget-notice"><Notice /></div>
                    {/*ResearchGate widget*/}
                    <div className="card mb-3" id="widget-researchgate"><ResearchGate /></div>
                    {/*Related sites widget*/}
                    <div className="card mb-3" id="widget-relatedsites"><RelatedSites /></div>
                    {/*Counter widget*/}
                    <div className="card mb-3" id="widget-counter"><Visit /></div>
                    {/*Google search widget*/}
                    <div className="card mb-3" id="widget-google"><Google content={content} /></div>
                </div>
            </div>
        </div>
    );
};

export default PapersList;
